*{
    margin: 0;
}
.dark-background{
    background: #18181a;
    z-index: -999;
    position: absolute;
    width: 100%;
    height: 100vh;
}
.coin-app{
    width: 100%;
    margin: 0 auto 0 auto;
    background: #18181a;
    max-height: auto;
    position: absolute;
    z-index: 999;
}

.coin-search{
    padding-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coin-text{
    color:#fff;
    text-align: center;
}

.coin-input{
    background: linear-gradient(to right, #9425e3, #4c04f9);
    width: 250px;
    height: 30px;
    color: #fff;
    border-radius: 7px;
}