.coin-container {
  max-width: 100%;
  margin-top: 100px;
  background: #18181a;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
}

.coin {
  max-width: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.coin img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.coin h1 {
  font-size: 20px;
  width: 150px;
}

.coin-symbol {
  text-transform: uppercase;
}

.coin-row {
  display: flex;
  flex-direction: row;
  justify-items: start;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #d7d7d7;
  max-width: 900px;
  width: 100%;
}

.coin-data {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 100px;
}


.red {
  color: red;
}

.green {
  color: green;
}
